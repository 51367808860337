<template>
  <div>
    <b-button
      size="sm"
      variant="secondary"
      class="my-2 btn-action "
      style="border-style: none;"
      @click="hasHistory() ? $router.go(-1) : $router.push('/')"
      v-if="$route.params.id != undefined"
    >
      <feather-icon icon="ArrowLeftIcon" size="25" />
    </b-button>
    <b-card>
      <b-row>
        <b-col cols="12" lg="6">
          <h3 class="font-weight-bolder mb-50">{{ product.NombreProducto }}</h3>
          <p>{{ product.Formato }}</p>
          <div class="mb-2">
            <b-badge variant="light" pill>REF: {{ product.Articulo }}</b-badge>
          </div>
          <div>
            <b-row>
              <b-col cols="12" lg="6" xl="4">
                <div class="bg-gray-light py-1 px-2 mb-2 mb-lg-0">
                  <p class="mb-50">
                    <strong>Stock ({{ $i18n.messages.Piezas }}):</strong>
                  </p>
                  <p class="mb-0" v-if="product.Stock">
                    {{
                      Number(product.Stock.España).toLocaleString("de-DE", {
                        maximumFractionDigits: 4,
                      })
                    }}
                  </p>
                </div>
              </b-col>
              <b-col cols="12" lg="6" xl="4" v-if="product.M2xPza > 0">
                <div class="bg-gray-light py-1 px-2 mb-2 mb-lg-0">
                  <p class="mb-50">
                    <strong>Stock ({{ $i18n.messages.m2 }}):</strong>
                  </p>
                  <p class="mb-0" v-if="product.Stock">
                    {{
                      Number(product.StockM2.España).toLocaleString("de-DE", {
                        maximumFractionDigits: 4,
                      })
                    }}
                  </p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col cols="12" lg="6" v-if="user.rol_id != 0">
          <div class="border-card p-1 p-lg-3">
            <h3 class="text-center font-weight-bolder mb-2">
              {{ $i18n.messages.selectCantPedido }}
            </h3>
            <div>
              <div class="mb-2">
                <b-form-select
                  id="type-select"
                  v-model="typeSelect"
                  :options="typesSelect"
                  placeholder="Unidades"
                />
              </div>
              <div v-if="typeSelect == 'pcs'">
                <b-row class="mt-2 mt-lg-1">
                  <b-col cols="12" lg="6">
                    <h4 class="font-weight-bolder mb-0">
                      {{ $i18n.messages.Pales }}
                    </h4>
                    <p class="mb-50">
                      (1 {{ $i18n.messages.Pale }} = {{ product.CajasPale }}
                      {{ $i18n.messages.Cajas }})
                    </p>
                  </b-col>
                  <b-col cols="12" lg="6">
                    <b-input-group>
                      <template #prepend>
                        <b-button variant="outline-primary" @click="menosPales">
                          -
                        </b-button>
                      </template>
                      <b-form-input
                        readonly
                        v-model="quantityPales"
                        type="number"
                        min="0"
                        class="text-center font-weight-bolder"
                      />
                      <template #append>
                        <b-button variant="outline-primary" @click="masPales">
                          +
                        </b-button>
                      </template>
                    </b-input-group>
                  </b-col>
                </b-row>
                <b-row class="mt-2 mt-lg-1">
                  <b-col cols="12" lg="6">
                    <h4 class="font-weight-bolder mb-0">
                      {{ $i18n.messages.Cajas }}
                    </h4>
                    <p class="mb-50">
                      (1 {{ $i18n.messages.Caja }} = {{ product.PiezasCaja }}
                      {{ $i18n.messages.Piezas }})
                    </p>
                  </b-col>
                  <b-col cols="12" lg="6">
                    <b-input-group>
                      <template #prepend>
                        <b-button variant="outline-primary" @click="menosCajas">
                          -
                        </b-button>
                      </template>
                      <b-form-input
                        v-model="quantityCajas"
                        type="number"
                        min="0"
                        class="text-center font-weight-bolder"
                      />
                      <template #append>
                        <b-button variant="outline-primary" @click="masCajas">
                          +
                        </b-button>
                      </template>
                    </b-input-group>
                  </b-col>
                </b-row>
                <b-row class="mt-2 mt-lg-1">
                  <b-col cols="12" lg="6">
                    <h4 class="font-weight-bolder mb-50">
                      {{ $i18n.messages.Piezas }}
                    </h4>
                  </b-col>
                  <b-col cols="12" lg="6">
                    <b-input-group>
                      <template #prepend>
                        <b-button
                          variant="outline-primary"
                          @click="menosPiezas"
                        >
                          -
                        </b-button>
                      </template>
                      <b-form-input
                        v-model="quantityPiezas"
                        type="number"
                        min="0"
                        class="font-weight-bolder text-center"
                      />
                      <template #append>
                        <b-button variant="outline-primary" @click="masPiezas">
                          +
                        </b-button>
                      </template>
                    </b-input-group>
                  </b-col>
                </b-row>
              </div>
              <div v-if="typeSelect == 'm2'">
                <b-row class="mt-2 mt-lg-1">
                  <b-col cols="12" lg="6">
                    <h4 class="font-weight-bolder mb-50">
                      {{ $i18n.messages.Cantidad }}
                    </h4>
                  </b-col>
                  <b-col cols="12" lg="6">
                    <b-input-group>
                      <b-form-input
                        v-model="quantityMetros"
                        :formatter="numberFormat"
                        min="0.000"
                      />
                      <!--  <b-button variant="outline-primary" @click="calculateMeters">
                        Calcular
                      </b-button> -->
                    </b-input-group>
                  </b-col>
                </b-row>
              </div>
              <b-col cols="12" class="mt-2">
                <div class="text-center"></div>
              </b-col>
              <div v-if="typeSelect != ''">
                <p class="d-flex align-items-center justify-content-between">
                  <span> {{ $i18n.messages.TotalPieces }}</span>
                  <span>
                    {{
                      Number.isNaN(Number(this.price.Output_pieces))
                        ? 0
                        : Number(this.price.Output_pieces).toLocaleString(
                            "de-DE",
                            {
                              maximumFractionDigits: 4,
                            }
                          )
                    }}
                  </span>
                </p>

                <p
                  class="d-flex align-items-center justify-content-between"
                  v-if="product.M2xPza > 0"
                >
                  <span>{{ $i18n.messages.TotalM2 }}</span>
                  <span>
                    {{
                      Number.isNaN(Number(this.price.Output_sqm))
                        ? 0
                        : Number(this.price.Output_sqm).toLocaleString(
                            "de-DE",
                            {
                              maximumFractionDigits: 4,
                            }
                          )
                    }}
                  </span>
                </p>

                <p
                  v-if="this.price.Amount != 0"
                  class="d-flex align-items-center justify-content-between"
                >
                  <span v-if="this.price.Price_unit == 'm2'">
                    {{ $i18n.messages.PriceM2 }}
                  </span>
                  <span v-else> {{ $i18n.messages.PricePieza }} </span>
                  <span>
                    {{
                      Number(this.price.Price).toLocaleString("de-DE", {
                        maximumFractionDigits: 4,
                      })
                    }}
                  </span>
                </p>

                <p
                  style="color:#aa4e36 !important;"
                  v-if="this.price.Amount != 0 && messageAjuste == true"
                >
                  {{ $i18n.messages.Desajuste }}
                </p>
                <p v-else></p>

                <p
                  v-if="typeSelect == 'pcs'"
                  class="d-flex align-items-center justify-content-between"
                >
                  <span>{{ $i18n.messages.totalWeight }}</span>
                  <span>
                    {{
                      Number(
                        currentPiezas * this.product.PesoPza
                      ).toLocaleString("de-DE", {
                        maximumFractionDigits: 4,
                      })
                    }}
                  </span>
                </p>

                <p class="d-flex align-items-center justify-content-between">
                  <span
                    ><b>{{ $i18n.messages.Price }}</b></span
                  >
                  <span>
                    {{
                      Number(this.price.Amount).toLocaleString("de-DE", {
                        maximumFractionDigits: 2,
                      })
                    }}€
                  </span>
                </p>

                <p class="d-flex align-items-center justify-content-between">
                  <span>{{ $i18n.messages.Tasa }}</span>
                  <span>
                    {{
                      Number.isNaN(Number(this.price.Tasa_energetica))
                        ? 0
                        : Number(this.price.Tasa_energetica).toLocaleString(
                            "de-DE",
                            {
                              maximumFractionDigits: 2,
                            }
                          )
                    }}€
                  </span>
                </p>

                <p
                  style="color:red !important;"
                  v-if="
                    currentPiezas > product.Stock.España ||
                      quantityMetros > product.StockM2.España
                  "
                >
                  {{ $i18n.messages.MoreQuant }}
                </p>
                <p v-else></p>
              </div>
              <div class="text-center" v-if="typeSelect != ''">
                <b-button
                  variant="primary"
                  style="padding: 10px 60px; margin-top: 10px"
                  :disabled="
                    (currentCustomer == null && user.rol_id != 2) ||
                      Number(product.Stock.España) < 0 ||
                      price.Amount == 0
                  "
                  @click="formAddProduct"
                >
                  {{ $i18n.messages.Anyadir }}
                </b-button>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-modal ref="my-modal" hide-footer>
        <div class="d-block text-center" v-if="$route.params.id != undefined">
          <b-row v-if="successAdd">
            <b-col cols="12">
              <div class="my-2">
                <p class="h1 text-center">
                  {{ $i18n.messages.ProductoAnyadido }}
                </p>
                <div class="mt-2 d-flex justify-content-center">
                  <a :href="$router.resolve({ name: 'products' }).href">
                    <b-button variant="secondary" class="ml-2">
                      {{ $i18n.messages.SeguirComprando }}
                    </b-button>
                  </a>
                  <a :href="$router.resolve({ name: 'cart' }).href">
                    <b-button variant="secondary" class="ml-2">
                      {{ $i18n.messages.IrCarrito }}
                    </b-button>
                  </a>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row v-else-if="errorAdd">
            <b-col cols="12">
              <div class="my-2">
                <p class="h1 text-center">
                  {{ $i18n.messages.ErrorProductoAnyadido }}
                </p>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="d-block text-center" v-else>
          <b-row v-if="successAdd">
            <b-col cols="12">
              <div class="my-2">
                <p class="h1 text-center">
                  Producto editado correctamente
                </p>
                <div class="mt-2 d-flex justify-content-center">
                  <a :href="$router.resolve({ name: 'cart' }).href">
                    <b-button variant="secondary" class="ml-2">
                      {{ $i18n.messages.IrCarrito }}
                    </b-button>
                  </a>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="errorAdd">
            <b-col cols="12">
              <div class="my-2">
                <p class="h1 text-center">
                  {{ $i18n.messages.ErrorProductoAnyadido }}
                </p>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BButton,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BProgress,
  BBadge,
} from "bootstrap-vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: [
    "idProd",
    "unitsRec",
    "quantityPalesRec",
    "quantityCajasRec",
    "quantityPiezasRec",
  ],
  components: {
    BCard,
    BCol,
    BRow,
    BTable,
    BButton,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BProgress,
    BBadge,
  },
  data() {
    return {
      modal: true,
      quantityPales: 0,
      quantityCajas: 0,
      quantityPiezas: 0,
      quantityMetros: 0.0,
      currentCajas: 0,
      currentPiezas: 0,
      currentM2: 0,
      currentPrecio: 0,
      messageAjuste: false,
      typeSelect: "",
      typesSelect: [
        { value: "", text: this.$i18n.messages.selectUnity },
        { value: "pcs", text: this.$i18n.messages.Piezas },
        { value: "m2", text: this.$i18n.messages.m2 },
      ],
    };
  },
  created() {
    this.setLoading(true);

    /*   this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      console.log("CIERRA");
      this.setSuccessAdd(false);
      this.setErrorAdd(false);
      this.quantityPales = 0;
      this.quantityCajas = 0;
    }); */

    console.log("this.$route.params.id: ", this.$route.params.id);
    console.log("PARAM1::  ", this.idProd);
    console.log("PARAM2::  ", this.unitsRec);
    console.log("PARAM3::  ", this.quantityPalesRec);
    console.log("PARAM4::  ", this.quantityCajasRec);
    console.log("PARAM5::  ", this.quantityPiezasRec);

    var id = this.$route.params.id;
    if (id == undefined) {
      id = this.idProd;
    }

    this.getProduct(id).then(() => {
      console.log("produtco: ", this.product);

      if (this.product.M2xPza == 0) {
        console.log("ENTRA2");
        this.typeSelect = "pcs";
        this.typesSelect = [{ value: "pcs", text: this.$i18n.messages.Piezas }];
      }
      if (this.$route.params.id == undefined) {
        if (this.quantityPalesRec != this.quantityCajasRec) {
          this.typeSelect = "pcs";
          setTimeout(() => {
            this.quantityPales = this.quantityPalesRec;
            this.quantityCajas = this.quantityCajasRec;
            this.quantityPiezas = this.quantityPiezasRec;
          }, 400);
        } else {
          console.log("METROSSS: ", this.quantityPalesRec);
          this.typeSelect = "m2";
          setTimeout(() => {
            this.quantityMetros = this.quantityPalesRec;
          }, 400);
        }
      }
      this.limitQuantity();

      this.setLoading(false);
    });
  },
  computed: {
    ...mapGetters({
      product: "products/getProduct",
      price: "products/getPrice",
      productAdd: "products/getProductAdd",
      lotes: "products/getLotes",
      user: "auth/getUser",
      currentCustomer: "customers/getCurrentCustomer",
      successAdd: "carts/getSuccessAdd",
      errorAdd: "carts/getErrorAdd",
    }),
    hasLotes() {
      return this.lotes.length;
    },
    modalAddShow: {
      get() {
        return this.$store.getters["products/getShowModalAdd"];
      },
      set(val) {
        this.$store.commit("products/setShowModalAdd", val);
      },
    },
    BarValue() {
      if (this.quantityPales === 0 && this.quantityCajas === 0) {
        return 0;
      } else if (this.quantityCajas === 0) {
        return 100;
      } else if (this.quantityPales === this.quantityLimitPalets) {
        return (this.quantityCajas / this.quantityLimitCajas) * 100;
      } else {
        return (this.quantityCajas / this.product.CajasPale) * 100;
      }
    },
    BarVariant() {
      if (this.BarValue === 0) {
        return "info";
      } else if (this.BarValue === 100) {
        if (
          this.quantityPales === this.quantityLimitPalets &&
          this.quantityCajas > 0
        ) {
          return "danger";
        } else {
          return "light";
        }
      } else {
        return "light";
      }
    },

    FaltanCajas() {
      if (
        this.quantityPales === this.quantityLimitPalets &&
        this.quantityLimitPalets !== 0
      ) {
        return this.quantityLimitCajas - this.quantityCajas;
      } else {
        return this.product.CajasPale - this.quantityCajas;
      }
    },
    total() {
      let total = 0;
      if (this.quantityCajas > 0) {
        total += this.quantityCajas * this.product.Metroscaja;
      }
      if (this.quantityPales > 0) {
        total +=
          this.quantityPales * this.product.CajasPale * this.product.Metroscaja;
      }
      return total;
    },
    totalCajas() {
      // eslint-disable-next-line no-mixed-operators
      return this.quantityPales * this.product.CajasPale + this.quantityCajas;
    },
    totalWeight() {
      let totalWeight = 0;
      if (this.quantityCajas > 0) {
        totalWeight +=
          this.quantityCajas * this.product.Metroscaja * this.product.PesoM2;
      }
      if (this.quantityPales > 0) {
        totalWeight +=
          this.quantityPales *
          this.product.CajasPale *
          this.product.Metroscaja *
          this.product.PesoM2;
      }
      return totalWeight;
    },
    quantityLimitCajasTotales() {
      console.log(
        "LIMIT CAJAS:: ",
        Math.floor(this.product.Stock.España / this.product.PiezasCaja)
      );
      return Math.floor(this.product.Stock.España / this.product.PiezasCaja);
    },
    quantityLimitPalets() {
      console.log(
        "LIMIT PALES:: ",
        Math.floor(this.quantityLimitCajasTotales / this.product.CajasPale)
      );
      return Math.floor(
        this.quantityLimitCajasTotales / this.product.CajasPale
      );
    },
    quantityLimitCajas() {
      return Math.floor(
        this.quantityLimitCajasTotales -
          this.quantityLimitPalets * this.product.CajasPale
      );
    },
  },
  watch: {
    modalAddShow() {
      this.setSuccessAdd(false);
      this.setErrorAdd(false);
      this.quantityPales = 0;
      this.quantityCajas = 0;
    },
    quantityPales() {
      this.limitQuantity();
    },
    quantityCajas() {
      this.limitQuantity();
    },
    quantityPiezas() {
      this.limitQuantity();
    },
    quantityMetros() {
      this.limitQuantity();
    },
    typeSelect(newVal) {
      console.log(newVal);
      this.quantityPales = 0;
      this.quantityCajas = 0;
      this.quantityPiezas = 0;
      this.quantityMetros = 0.0;
      this.currentCajas = 0;
      this.currentPiezas = 0;
      this.currentM2 = 0;
      this.currentPrecio = 0;
      this.price = {};
      this.messageAjuste = false;
      //  this.limitQuantity();
    },
  },
  methods: {
    numberFormat(value) {
      if (value.charAt(value.length - 1) == ".") {
        return value;
      } else {
        var points = parseFloat(value);
        return points;
      }
    },
    hasHistory() {
      return window.history.length > 2;
    },
    calculateMeters() {
      this.limitQuantity();
    },
    /*  showModal() {
      if (this.currentCustomer === "" || this.currentCustomer === null) {
        this.recoverCurrentCustomer();
      } else {
        this.setShowModalAdd(true);
      }
    }, */
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    ...mapActions({
      getProduct: "products/getProduct",
      getPrice: "products/getPrice",
      addProduct: "carts/addProduct",
    }),
    ...mapMutations({
      setShowModalAdd: "products/setShowModalAdd",
      setLoading: "app/setLoading",
      recoverCurrentCustomer: "customers/recoverCurrentCustomer",
      setSuccessAdd: "carts/setSuccessAdd",
      setErrorAdd: "carts/setErrorAdd",
      setShowModalCustomers: "customers/setShowModalCustomers",
    }),
    limitQuantity() {
      this.setLoading(true);

      if (this.quantityPales < 0) {
        this.quantityPales = 0;
      }
      if (this.quantityCajas < 0) {
        this.quantityCajas = 0;
      }
      if (this.quantityPiezas < 0) {
        this.quantityPiezas = 0;
      }
      if (this.quantityMetros < 0) {
        this.quantityMetros = 0.0;
      }

      this.currentCajas =
        this.quantityPales * this.product.CajasPale + this.quantityCajas;

      console.log("currentCajas: ", this.currentCajas);

      var totalPiezas =
        this.currentCajas * this.product.PiezasCaja + this.quantityPiezas;
      console.log(
        "piezas: ",
        this.currentCajas * this.product.PiezasCaja + this.quantityPiezas
      );
      console.log("YO: ", this.user);

      // this.currentPiezas = totalPiezas;

      var params = {
        idProduct: this.product.idProducto,
        codcli: "",
        input: "",
        quantity: 0,
      };

      if (this.quantityMetros > 0) {
        params.input = "m2";
        params.quantity = this.quantityMetros;
      } else {
        params.input = "pcs";
        params.quantity = totalPiezas;
      }

      if (this.currentCustomer != null) {
        console.log("CLIENTE: ", this.currentCustomer.Cliente);

        params.codcli = this.currentCustomer.Cliente;
      } else if (this.user.rol_id == 2) {
        console.log("CLIENTE YO: ", this.user.id_cliente);

        params.codcli = this.user.id_cliente;
      }

      console.log(params);
      this.getPrice(params).then(() => {
        console.log("precio: ", this.price);
        console.log("Piezas:: ", totalPiezas);
        console.log("Metros:: ", this.quantityMetros);

        if (this.quantityMetros != "0.") {
          if (this.price.Amount == 0) {
            this.quantityPales = 0;
            this.quantityCajas = 0;
            this.quantityPiezas = 0;
            this.quantityMetros = 0;
            this.currentCajas = 0;
            this.currentPiezas = 0;
            this.currentM2 = 0;
            this.currentPrecio = 0;
            this.price = {};
            this.messageAjuste = false;
          }
          if (totalPiezas == 0 && this.quantityMetros > 0) {
            this.currentM2 = this.price.Output_sqm;
            if (this.quantityMetros != this.price.Output_sqm) {
              this.messageAjuste = true;
            } else {
              this.messageAjuste = false;
            }
          }
        } else if (totalPiezas > 0 && this.quantityMetros == 0) {
          if (totalPiezas > 0 && this.quantityMetros == 0) {
            this.currentPiezas = this.price.Output_pieces;

            console.log("totalPiezas:: ", totalPiezas);
            console.log(
              "this.price.Output_pieces:: ",
              this.price.Output_pieces
            );

            if (totalPiezas != this.price.Output_pieces) {
              this.messageAjuste = true;
            } else {
              this.messageAjuste = false;
            }
          }
        }
        this.setLoading(false);
      });
    },
    menosPales() {
      this.quantityPales--;
    },
    masPales() {
      this.quantityPales++;
    },
    menosCajas() {
      this.quantityCajas--;
    },
    masCajas() {
      this.quantityCajas++;
    },
    menosPiezas() {
      this.quantityPiezas--;
    },
    masPiezas() {
      this.quantityPiezas++;
    },
    formAddProduct() {
      console.log(this.product);
      console.log(this.currentPiezas);
      if (this.typeSelect == "pcs" && !Number.isInteger(this.quantityCajas)) {
        this.quantityCajas = 0;
      }
      if (this.typeSelect == "pcs" && !Number.isInteger(this.quantityPales)) {
        this.quantityPales = 0;
      }
      if (this.typeSelect == "pcs" && !Number.isInteger(this.quantityPiezas)) {
        this.quantityPiezas = 0;
      }

      var prodToAdd = {
        codart: this.product.Articulo,
        cantidad: this.price.Output_qty,
        unitmeas: this.price.Output_unit,
        picking: 0,
        estado: 0,

        //Ponemos en estos campos ay existentes en BBDD que no se usan el n de cajas, de pales y de piezas
        cajasTotales:
          this.typeSelect == "pcs" ? this.quantityCajas : this.quantityMetros,
        cajasPalet:
          this.typeSelect == "pcs" ? this.quantityPales : this.quantityMetros,
        metrosCaja:
          this.typeSelect == "pcs" ? this.quantityPiezas : this.quantityMetros,

        peso: this.price.Output_qty * this.product.PesoPza,
        amount: this.price.Amount,
        nombre: this.product.Nombre,
        tasa: this.price.Tasa_energetica,
      };
      console.log(prodToAdd);

      this.addProduct(prodToAdd);
      this.showModal();
    },
  },
};
</script>
